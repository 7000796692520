import AxiosService from '@/services/AxiosService'
class AuthService {
  async passwordConfirm(payload) {
    return await AxiosService.post("/restore-password-confirm", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getTokenAdmin(payload) {
    return await AxiosService.get(`/admin-login?secret=${payload}`).then( (response)=> {
        return response.data;
    }).catch(function (error) {
        console.log(error);
    });
}

  async restorePassword(payload) {
    return await AxiosService.post("/restore-password", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async login(payload) {
    return await AxiosService.post("/login", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async registration(payload) {
    return await AxiosService.post("/registration", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new AuthService()
