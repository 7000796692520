<template>
  <div class="entry-form text-center">
    <img
      v-if="isLoaded"
      :src="require('@/assets/images/rolling.svg')"
      alt="loader"
    />
    <template v-else-if="success">
      <div class="mb-5">
        <svg
          fill="none"
          height="54"
          viewBox="0 0 60 54"
          width="60"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.9535 54H16.0465C5.86046 54 0 48.0973 0 37.8378V18.1622C0 7.9027 5.86046 2 16.0465 2H35.5814C36.7256 2 37.6744 2.95568 37.6744 4.10811C37.6744 5.26054 36.7256 6.21622 35.5814 6.21622H16.0465C8.06512 6.21622 4.18605 10.1232 4.18605 18.1622V37.8378C4.18605 45.8768 8.06512 49.7838 16.0465 49.7838H43.9535C51.9349 49.7838 55.8139 45.8768 55.8139 37.8378V23.7838C55.8139 22.6314 56.7628 21.6757 57.907 21.6757C59.0512 21.6757 60 22.6314 60 23.7838V37.8378C60 48.0973 54.1395 54 43.9535 54Z"
            fill="#292D32"
          />
          <path
            d="M30.2667 30C27.8914 30 25.4879 29.2688 23.65 27.7784L14.7994 20.7479C13.8945 20.0167 13.7249 18.6949 14.4601 17.795C15.1953 16.8951 16.5242 16.7264 17.4291 17.4576L26.2797 24.4881C28.4287 26.2035 32.0764 26.2035 34.2254 24.4881L37.562 21.8446C38.4668 21.1134 39.8242 21.254 40.5311 22.1821C41.2663 23.082 41.1249 24.4318 40.1918 25.1349L36.8551 27.7784C35.0454 29.2688 32.6419 30 30.2667 30Z"
            fill="#292D32"
          />
          <path
            d="M51 19C46.0431 19 42 14.7323 42 9.5C42 4.26769 46.0431 0 51 0C55.9569 0 60 4.26769 60 9.5C60 14.7323 55.9569 19 51 19ZM51 4.38462C48.3415 4.38462 46.1538 6.69385 46.1538 9.5C46.1538 12.3062 48.3415 14.6154 51 14.6154C53.6585 14.6154 55.8462 12.3062 55.8462 9.5C55.8462 6.69385 53.6585 4.38462 51 4.38462Z"
            fill="#357875"
          />
        </svg>
      </div>
      <div class="entry-form__title">{{ $t("auth.recovery_pass") }}</div>
      <p class="pb-4">{{ $t("auth.send_text") }}</p>
      <div class="entry-form__list">
        <div class="entry-form__item _submit pt-0">
          <router-link class="btn _bg-green" to="/login">{{
            $t("auth.good")
          }}</router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mb-5">
        <svg
          fill="none"
          height="59"
          viewBox="0 0 64 59"
          width="64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.9535 59H16.0465C5.86046 59 0 53.0973 0 42.8378V23.1622C0 12.9027 5.86046 7 16.0465 7H35.5814C36.7256 7 37.6744 7.95568 37.6744 9.10811C37.6744 10.2605 36.7256 11.2162 35.5814 11.2162H16.0465C8.06512 11.2162 4.18605 15.1232 4.18605 23.1622V42.8378C4.18605 50.8768 8.06512 54.7838 16.0465 54.7838H43.9535C51.9349 54.7838 55.814 50.8768 55.814 42.8378V28.7838C55.814 27.6314 56.7628 26.6757 57.907 26.6757C59.0512 26.6757 60 27.6314 60 28.7838V42.8378C60 53.0973 54.1395 59 43.9535 59Z"
            fill="#292D32"
          />
          <path
            d="M30.2667 35C27.8914 35 25.4879 34.2688 23.65 32.7784L14.7994 25.7479C13.8945 25.0167 13.7249 23.6949 14.4601 22.795C15.1953 21.8951 16.5242 21.7264 17.4291 22.4576L26.2797 29.4881C28.4287 31.2035 32.0764 31.2035 34.2254 29.4881L37.562 26.8446C38.4668 26.1134 39.8242 26.254 40.5311 27.1821C41.2663 28.082 41.1249 29.4318 40.1918 30.1349L36.8551 32.7784C35.0454 34.2688 32.6419 35 30.2667 35Z"
            fill="#292D32"
          />
          <path
            d="M42.4603 0.460261C43.0739 -0.15342 44.0689 -0.15342 44.6826 0.460261L53 8.77766L61.3174 0.460261C61.9311 -0.15342 62.9261 -0.15342 63.5397 0.460261C64.1534 1.07394 64.1534 2.06892 63.5397 2.6826L55.2223 11L63.5397 19.3174C64.1534 19.9311 64.1534 20.9261 63.5397 21.5397C62.9261 22.1534 61.9311 22.1534 61.3174 21.5397L53 13.2223L44.6826 21.5397C44.0689 22.1534 43.0739 22.1534 42.4603 21.5397C41.8466 20.9261 41.8466 19.9311 42.4603 19.3174L50.7777 11L42.4603 2.6826C41.8466 2.06892 41.8466 1.07394 42.4603 0.460261Z"
            fill="#C62424"
          />
        </svg>
      </div>
      <div class="entry-form__title error-text">
        {{ $t("auth.sent_error") }}
      </div>
      <p class="pb-4">{{ $t("auth.send_text_error") }}</p>
      <div class="entry-form__list">
        <div class="entry-form__item _submit pt-0">
          <router-link class="btn" to="/reset-password">{{
            $t("auth.try_again")
          }}</router-link>
        </div>
      </div>
    </template>
    <alert v-if="$alert.title" />
  </div>
</template>

<script>
import AuthService from "@/api/serviceAuth";
export default {
  name: "RecoveryPassword",
  data() {
    return {
      isLoaded: false,
      success: false,
    };
  },
  created() {
    this.resetPasswordConfirm();
  },
  methods: {
    resetPasswordConfirm() {
      if (!this.$route.query.uid || !this.$route.query.code) {
        return;
      }
      this.isLoaded = true;
      const params = {
        user_id: this.$route.query.uid,
        code: this.$route.query.code,
      };
      AuthService.passwordConfirm(params)
        .then((resp) => {
          if (resp.type === "Success") {
            this.$alert({
              title: this.$t("sent_success"),
              text: this.$t("auth.send_text"),
              type: "success",
            });
            this.success = true;
          } else {
            this.$alert({
              title: this.$t("sent_error"),
              text: resp.message,
            });
            this.success = false;
          }
        })
        .finally(() => {
          this.isLoaded = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-text {
  color: #c62424 !important;
}

.switch-password .btn.btn-empty {
  padding: 0;
}
</style>